import { useHttp } from 'http/httpService';
export function useTradeInApi() {
    var http = useHttp();
    return {
        getApplicantData: function () {
            return http
                .get('/api/users/me')
                .then(function (response) { return response.data; });
        },
        getUserData: function (login) {
            return http
                .get("/api/users/".concat(login, "?card"))
                .then(function (response) { return response.data; });
        },
        getPartiesWithIdentyficationNumber: function (clientId) {
            return http
                .get("/api/clients/".concat(clientId, "/parties?withIdentificationNumber"))
                .then(function (response) { return response.data; });
        },
        getParty: function (partyId) {
            return http
                .get("/api/parties/".concat(partyId))
                .then(function (response) { return response.data; });
        },
        getOrdersByParty: function (partyId) {
            return http.get("/api/orders?ofParty=".concat(partyId))
                .then(function (response) { return response.data; });
        },
        getSelectSalesmen: function () {
            return http
                .get('/api/users?withRole=USED_CAR_SALES')
                .then(function (response) { return response.data; });
        },
        addFile: function (file) {
            var formData = new FormData();
            formData.append('file', file);
            return http
                .post('/api/files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (response) {
                return {
                    fileUrl: response.headers.location
                };
            });
        },
        deleteFile: function (fileLocation) {
            return http.delete("/api".concat(fileLocation));
        },
        loadLocation: function (location) {
            return http
                .get('/api' + location, { responseType: 'blob' });
        },
        saveTradeId: function (tradeInData) {
            return http
                .post('/api/discount/tradein', tradeInData).then(function (response) { return response; });
        },
        getTradeIn: function (tradeInId) {
            return http
                .get("/api/discount/tradein/".concat(tradeInId)).then(function (response) { return response; });
        },
        getTradeInsForParty: function (identificationNumbers) {
            return http
                .post('/api/discount/specialoffer/preview?kind=TRADE_IN', { identificationNumbers: identificationNumbers })
                .then(function (response) { return response.data; });
        },
        getVcpDiscountSettlementOfOrder: function (orderId) {
            return http.get("/api/concessions/settlements?ofOrder=".concat(orderId))
                .then(function (response) { return response.data; });
        },
        getDrsDiscountsForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/extra-sale-discounts?forIdentificationNumber=".concat(identificationNumber))
                .then(function (response) { return response.data; });
        }
    };
}
