var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import './newTradeInForm.css';
import { ViewSection } from '../viewSection/ViewSection';
import { ApplicantSection } from '../applicantSection/ApplicantSection';
import { OrderingPartySection } from '../orderingPartySection/OrderingPartySection';
import { TradeInDiscountSection } from '../tradeInDiscountSection/TradeInDiscountSection';
import { AdditionalInfosSection } from '../additionalInfosSection/AdditionalInfosSection';
import { useApplicantSection } from '../applicantSection/useApplicantSection';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useOrderingPartySection } from '../orderingPartySection/useOrderingPartySection';
import { useTradeInSection } from '../tradeInDiscountSection/useTradeInDiscountSection';
import { useAdditionalInfosSection } from '../additionalInfosSection/useAdditionalInfosSection';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import { Button } from 'components/button/Button.component';
import { formatShortDate } from 'utils/formatShortDate';
import { useStatus } from 'common/statusContext';
import { useTradeInApi } from '../useTradeInApi';
import { InfoDialog } from 'pages/specialOffer/InfoDialog';
import { Spinner } from 'components/spinner/Spinner';
import ModelDictionary from 'common/modelDictionary';
export var NewTradeInForm = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useState(false), openConfirmDialog = _b[0], setOpenConfirmDialog = _b[1];
    var _c = useState(''), dialogMessage = _c[0], setDialogMessage = _c[1];
    var applicantSectionData = useApplicantSection({ mode: 'CREATE' });
    var orderingPartySectionData = useOrderingPartySection();
    var tradeInDiscountSectionData = useTradeInSection();
    var additionalInfosSectionData = useAdditionalInfosSection();
    var tradeInApi = useTradeInApi();
    var _d = window.location.href.split('?'), searchParamsString = _d[1];
    var searchParams = new URLSearchParams(searchParamsString);
    function onSaveTradeInClick() {
        return __awaiter(this, void 0, void 0, function () {
            function mapAttachment(attachment, type, subtype) {
                if (subtype === void 0) { subtype = null; }
                return tradeInDiscountSectionData[attachment].map(function (document) {
                    return {
                        type: type,
                        subtype: subtype,
                        name: document.fileName,
                        link: document.fileUrl
                    };
                });
            }
            var selectedOrder, _a, modelId, modelYear, clientId, tradeInData, saveNewSpecialOfferResponse, clientId_1, partyId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        selectedOrder = orderingPartySectionData.partyCars.find(function (car) { return car.id === orderingPartySectionData.selectedCar; });
                        _a = selectedOrder.modelId.split('_'), modelId = _a[0], modelYear = _a[1];
                        clientId = searchParams.has('clientId') ? searchParams.get('clientId') : searchParams.get('partyId').split('#')[0];
                        tradeInData = {
                            applicationDate: formatShortDate(applicantSectionData.applicationDate),
                            showroom: {
                                id: applicantSectionData.organizationId,
                                name: applicantSectionData.organizationName
                            },
                            sellers: [
                                {
                                    type: 'assistantId',
                                    id: applicantSectionData.assistantId,
                                    name: applicantSectionData.assistantName
                                },
                                {
                                    type: 'applicantId',
                                    id: applicantSectionData.applicantId,
                                    name: applicantSectionData.applicantName
                                },
                                {
                                    type: 'selectSalesman',
                                    id: tradeInDiscountSectionData.selectedSelectSalesman.login,
                                    name: tradeInDiscountSectionData.selectedSelectSalesman.name
                                }
                            ],
                            party: {
                                address: orderingPartySectionData.party.address,
                                dolId: encodeURIComponent(orderingPartySectionData.party.id),
                                dolName: orderingPartySectionData.party.name,
                                dominantPKDCode: null,
                                identificationNumber: orderingPartySectionData.party.identificationNumber,
                                name: null,
                                type: orderingPartySectionData.party.type,
                                id: null
                            },
                            order: {
                                orderId: orderingPartySectionData.selectedCar,
                                invoicePrice: null
                            },
                            cars: [
                                {
                                    type: 'NEW',
                                    dealerOrder: selectedOrder.dealerOrder,
                                    vin: selectedOrder.vin,
                                    model: ModelDictionary.nameFor(modelId),
                                    modelYear: modelYear,
                                    commonOrderNumber: selectedOrder.commonOrderNumber,
                                    pno12: selectedOrder.pno12,
                                    engine: selectedOrder.engineFamily,
                                    version: selectedOrder.version,
                                    mileage: null,
                                    registrationDate: null,
                                    repurchaseDate: null
                                },
                                {
                                    type: 'SELEKT',
                                    dealerOrder: null,
                                    vin: tradeInDiscountSectionData.vin,
                                    model: null,
                                    modelYear: null,
                                    commonOrderNumber: null,
                                    pno12: null,
                                    engine: null,
                                    version: null,
                                    mileage: tradeInDiscountSectionData.mileage,
                                    registrationDate: formatShortDate(tradeInDiscountSectionData.firstRegistrationDate),
                                    repurchaseDate: formatShortDate(tradeInDiscountSectionData.repurchaseDate)
                                }
                            ],
                            attachments: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], mapAttachment('carPurchaseDocument', 'SELEKT_CAR', 'carPurchaseDocument'), true), mapAttachment('registrationCertificateCopy', 'SELEKT_CAR', 'registrationCertificateCopy'), true), mapAttachment('newCarPurchaseDocument', 'NEW_CAR', 'newCarPurchaseDocument'), true), mapAttachment('handoverProtocol', 'NEW_CAR', 'handoverProtocol'), true), mapAttachment('consentDocument', 'CONSENT'), true), mapAttachment('otherDocuments', 'OTHER'), true),
                            additionalInfo: additionalInfosSectionData.additionalInfo,
                            clientId: clientId
                        };
                        setStatus('pending');
                        return [4 /*yield*/, tradeInApi.saveTradeId(tradeInData)];
                    case 1:
                        saveNewSpecialOfferResponse = _b.sent();
                        if (saveNewSpecialOfferResponse && saveNewSpecialOfferResponse.status === 200) {
                            setStatus('success');
                            if (searchParams.has('clientId')) {
                                clientId_1 = searchParams.get('clientId');
                                window.location.href = "#/customer-service/".concat(clientId_1, "/");
                            }
                            if (searchParams.has('partyId')) {
                                partyId = searchParams.get('partyId');
                                window.location.href = "#/fleet-service/".concat(encodeURIComponent(partyId));
                            }
                        }
                        else {
                            setStatus('error');
                            setDialogMessage('Nie udało się zapisać oferty specjalnej');
                            setOpenConfirmDialog(true);
                            console.error('Błąd zapisu oferty specjalnej');
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function isSaveDisabled() {
        return !orderingPartySectionData.isRequiredDataFilled || !tradeInDiscountSectionData.isRequiredDataFilled;
    }
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    return (React.createElement("div", { className: 'newTradeInFormWrapper' },
        React.createElement(ViewSection, { title: 'wnioskuj\u0105cy', dataTestid: "trade-in-applicant-section" },
            React.createElement(ApplicantSection, { applicantSectionData: applicantSectionData })),
        React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: "trade-in-client-section" },
            React.createElement(OrderingPartySection, { orderingPartySectionData: orderingPartySectionData })),
        React.createElement(ViewSection, { title: 'rabat trade-in', dataTestid: "trade-in-discount-section" },
            React.createElement(TradeInDiscountSection, { tradeInDiscountSectionData: tradeInDiscountSectionData })),
        React.createElement(ViewSection, { title: 'informacje dodatkowe', dataTestid: "trade-in-additional-infos" },
            React.createElement("div", { className: "newTradeInDiscountComment" },
                React.createElement(AdditionalInfosSection, { additionalInfosSectionData: additionalInfosSectionData }))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "tradeInSubmitButtonWrapper" },
            React.createElement("div", { className: "tradeInSubmitButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onSaveTradeInClick, disabled: isSaveDisabled() }, "wy\u015Blij zapytanie"))),
        React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
