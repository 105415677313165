var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { ViewSection } from '../viewSection/ViewSection';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import { Button } from 'components/button/Button.component';
import sessionStorage from 'common/sessionStorage';
import { useStatus } from 'common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import { useTradeInApi } from '../useTradeInApi';
import { ApplicantSection } from '../applicantSection/ApplicantSection';
import { useLocation } from 'react-router-dom';
import { OrderingPartyPreviewSection } from '../orderingPartySection/OrderingPartyPreviewSection';
import { TradeInDiscountPreviewSection } from '../tradeInDiscountSection/TradeInDiscountPreviewSection';
import { SettlementSection } from '../settlementSection/SettlementSection';
import UserContext from 'common/userContext';
import { AccordionViewSection } from '../accordionViewSection/AccordionViewSection';
import { VcpDiscountSettlement } from '../vcpDiscountSettlement/VcpDiscountSettlement';
import { DrsDiscountSection } from '../drsDiscountSection/DrsDiscountSection';
export var TradeInPreview = withCancellation(function () {
    var location = useLocation();
    var _a = location.search.split('?'), searchParamsString = _a[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var tradeInNumber = searchParams.get('tradeInNumber');
    var _b = useStatus(), status = _b.status, setStatus = _b.setStatus;
    var _c = useState(), tradeIn = _c[0], setTradeIn = _c[1];
    var _d = useState(), tradeInsForParty = _d[0], setTradeInsForParty = _d[1];
    var _e = useState(), vcpDiscountSettlement = _e[0], setVcpDiscountSettlement = _e[1];
    var _f = useState(), drsDiscountsForParty = _f[0], setDrsDiscountsForParty = _f[1];
    var tradeInApi = useTradeInApi();
    function isFromVCP() {
        return UserContext.isFromVCP();
    }
    function getOtherTradeInsForParty(identificationNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var tradeInsForPartyResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, tradeInApi.getTradeInsForParty([identificationNumber])];
                    case 1:
                        tradeInsForPartyResponse = _a.sent();
                        setTradeInsForParty(tradeInsForPartyResponse);
                        return [2 /*return*/];
                }
            });
        });
    }
    function getVcpDiscountsByOrderId(orderId) {
        return __awaiter(this, void 0, void 0, function () {
            var vcpDiscountSettlementByOrderIdResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, tradeInApi.getVcpDiscountSettlementOfOrder(orderId)];
                    case 1:
                        vcpDiscountSettlementByOrderIdResponse = _a.sent();
                        setVcpDiscountSettlement(vcpDiscountSettlementByOrderIdResponse);
                        return [2 /*return*/];
                }
            });
        });
    }
    function getDrsDiscountsForParty(identificationNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var drsDiscountsForPartyResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, tradeInApi.getDrsDiscountsForIdentificationNumber(identificationNumber)];
                    case 1:
                        drsDiscountsForPartyResponse = _a.sent();
                        setDrsDiscountsForParty(drsDiscountsForPartyResponse);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getTradeIn() {
            return __awaiter(this, void 0, void 0, function () {
                var tradeInResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!tradeInNumber) return [3 /*break*/, 2];
                            setStatus('pending');
                            return [4 /*yield*/, tradeInApi.getTradeIn(tradeInNumber)];
                        case 1:
                            tradeInResponse = _a.sent();
                            if (tradeInResponse && tradeInResponse.status === 200) {
                                setStatus('success');
                                setTradeIn(tradeInResponse.data);
                                getOtherTradeInsForParty(tradeInResponse.data.party.identificationNumber.value);
                                if (isFromVCP()) {
                                    getVcpDiscountsByOrderId(tradeInResponse.data.order.id);
                                    getDrsDiscountsForParty(tradeInResponse.data.party.identificationNumber.value);
                                }
                            }
                            else {
                                setStatus('error');
                                console.error('Błąd pobierania oferty specjalnej');
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        getTradeIn();
    }, [tradeInNumber]);
    function onCloseTradeInClick() {
        var previousState = sessionStorage.get('previousState');
        var clientId = sessionStorage.get('customer-service_client_id');
        var partyId = sessionStorage.get('fleet-service_party_id');
        switch (previousState) {
            case 'app.customer-service':
                window.location.href = "#/customer-service/".concat(clientId, "/");
                break;
            case 'app.fleet-service':
                window.location.href = "#/fleet-service/".concat(encodeURIComponent(partyId));
                break;
            default:
                window.location.href = '#/dashboard';
                break;
        }
    }
    var applicantSectionData = tradeIn && {
        applicationDate: new Date(tradeIn.applicationDate),
        organizationName: tradeIn.showroom.name,
        assistantName: tradeIn.sellers.find(function (seller) { return seller.type === 'assistantId'; }).name,
        applicantName: tradeIn.sellers.find(function (seller) { return seller.type === 'applicantId'; }).name,
        applicationId: tradeInNumber
    };
    var newCar = tradeIn && tradeIn.cars.find(function (car) { return car.type === 'NEW'; });
    var selectCar = tradeIn && tradeIn.cars.find(function (car) { return car.type === 'SELEKT'; });
    var orderingPartyPreviewData = tradeIn && {
        party: {
            name: tradeIn.party.dolName,
            identificationNumber: tradeIn.party.identificationNumber
        },
        car: newCar
    };
    var tradeInDiscountPreviewSectionData = tradeIn && {
        vin: selectCar.vin,
        firstRegistrationDate: selectCar.registrationDate,
        repurchaseDate: selectCar.repurchaseDate,
        mileage: selectCar.mileage,
        selectedSelectSalesman: tradeIn.sellers.find(function (seller) { return seller.type === 'selectSalesman'; }).name,
        carPurchaseDocument: tradeIn.attachments.filter(function (attachment) { return attachment.subtype === 'carPurchaseDocument'; }),
        registrationCertificateCopy: tradeIn.attachments.filter(function (attachment) { return attachment.subtype === 'registrationCertificateCopy'; }),
        newCarPurchaseDocument: tradeIn.attachments.filter(function (attachment) { return attachment.subtype === 'newCarPurchaseDocument'; }),
        handoverProtocol: tradeIn.attachments.filter(function (attachment) { return attachment.subtype === 'handoverProtocol'; }),
        consentDocument: tradeIn.attachments.filter(function (attachment) { return attachment.type === 'CONSENT'; }),
        otherDocuments: tradeIn.attachments.filter(function (attachment) { return attachment.type === 'OTHER'; }),
        supportVcpAmountNet: tradeIn.tradeIn.discount,
        changesHistory: tradeIn.statuses
    };
    var settlementData = tradeInsForParty;
    function hasAccessToOtherDiscountsData() {
        return UserContext.hasAnyRole('VCP_FLEET_SALES', 'VCP_SALES_MANAGER', 'VCP_SALES', 'VCP_ACCOUNTING', 'VCP_AUDIT', 'VCP_HEAD_OF_ACCOUNTING');
    }
    return (tradeIn ?
        React.createElement("div", { className: 'newTradeInFormWrapper' },
            React.createElement(ViewSection, { title: 'wnioskuj\u0105cy', dataTestid: "trade-in-applicant-section" },
                React.createElement(ApplicantSection, { applicantSectionData: applicantSectionData })),
            React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: "trade-in-client-section" },
                React.createElement(OrderingPartyPreviewSection, { orderingPartyPreviewData: orderingPartyPreviewData })),
            React.createElement(ViewSection, { title: 'rabat trade-in', dataTestid: "trade-in-discount-section" },
                React.createElement(TradeInDiscountPreviewSection, { tradeInDiscountPreviewSectionData: tradeInDiscountPreviewSectionData })),
            React.createElement(AccordionViewSection.Root, null,
                React.createElement(AccordionViewSection.Item, { value: 'trade-in-settlement-section' },
                    React.createElement(AccordionViewSection.Trigger, null, "rozliczenie trade in dla strony"),
                    React.createElement(AccordionViewSection.Content, null,
                        React.createElement(SettlementSection, { settlementData: settlementData }))),
                hasAccessToOtherDiscountsData() &&
                    React.createElement(React.Fragment, null,
                        React.createElement(AccordionViewSection.Item, { value: 'trade-in-vcp-discount-section' },
                            React.createElement(AccordionViewSection.Trigger, null, "rozliczenie rabatu VCP dla samochodu"),
                            React.createElement(AccordionViewSection.Content, null,
                                React.createElement(VcpDiscountSettlement, { vcpDiscountSettlement: vcpDiscountSettlement }))),
                        React.createElement(AccordionViewSection.Item, { value: 'trade-in-drs-discount-section' },
                            React.createElement(AccordionViewSection.Trigger, null, "rabat drs dla samochodu"),
                            React.createElement(AccordionViewSection.Content, null,
                                React.createElement(DrsDiscountSection, { drsDiscountsForParty: drsDiscountsForParty, con: newCar.commonOrderNumber }))))),
            React.createElement(HorizontalLine, { color: "#6D6D6D" }),
            React.createElement("div", { className: "tradeInSubmitButtonWrapper" },
                React.createElement("div", { className: "tradeInSubmitButton" },
                    React.createElement(Button, { variant: "primary", type: "button", onClick: onCloseTradeInClick }, "zamknij"))),
            status === 'pending' ? React.createElement(Spinner, null) : null)
        : null);
});
