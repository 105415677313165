import React from 'react';
import './settlementSection.css';
import { TextAmount } from 'components/textAmount/TextAmount';
import { discountStatusName } from 'pages/specialOffer/discountStatusName';
export function SettlementSection(_a) {
    var settlementData = _a.settlementData;
    return (settlementData && settlementData.length > 0 &&
        React.createElement("div", { className: 'tradeInSettlementSectionWrapper' },
            React.createElement("table", { className: "tradeInSettlementSectionTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementDealerCell" }, "Dealer"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementCarCell" }, "Samoch\u00F3d"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementDiscountAmountNetCell" }, "Kwota rabatu netto"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementStatusCell" }, "Status"))),
                React.createElement("tbody", null, settlementData.map(function (settlement) { return (React.createElement("tr", { key: settlement.id, className: 'tradeInSettlementSectionTableRow' },
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementDealerCell' }, settlement.dealerName),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementCarCell' }, "".concat(settlement.model, " (CON ").concat(settlement.commonOrderNumber, ")")),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementDiscountAmountNetCell' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.discountNet)),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementStatusCell' }, discountStatusName(settlement.status)))); })))));
}
